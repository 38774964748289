@import "../../../styles/_variables.module.scss";

.contenedorHuincha {
	position: relative;
	width: 100%;
	height: 32px;
		background-size: contain !important;
		background-repeat: no-repeat !important;
		background-position: center !important;
	a:hover {
		color: $AzulPrincipal;
    text-decoration: none;
	}
}

.contenedorInfoHuincha {
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2px 24px;
	.contenedorTexto {
		p {
			font: $ttHuinchaMobile;
			line-height: 14px;
			margin: 0;
		}
		strong {
			font-weight: 800;
	}
	}
	.btnHuincha {
		background-color: $AzulPrincipal;
		color: #fff;
		font-size: 12px;
		line-height: 16px;
		padding: 2px 8px;
		border-radius: 4px;
		margin-left: 40px;
		font-family: Nunito;
	}
}

@media (min-width: 576px) {
	.contenedorHuincha {
		height: 40px;
		// background-color: #24018A;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		
	}
	.contenedorInfoHuincha {
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		.contenedorTexto {
			display: flex;
			flex-direction: row;
			p {
				font: $ttHuinchaTablet;
				line-height: 30px;
			}
			strong {
				margin-right: 6px;
				font-weight: 700;
			}
		}
		.btnHuincha {
			padding: 2px 16px;
			margin-left: 24px;
		}
	}
}

@media (min-width: 768px) {
	.contenedorInfoHuincha {
		.contenedorTexto {
			p {
				font: $ttHuinchaDesktop;
			}
		}
		.btnHuincha {
			padding: 4px 24px;
			margin-left: 32px;
		}
	}
}
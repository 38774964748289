@import "../../../styles/_variables.module.scss";

.toTop {
  display: block;
  width: 100%;
  padding: $espaciado-16;
  font-size: $font-size-s;
  color: $blanco;
  background-color: $AzulPrincipalHover;
  text-align: center;
  text-decoration: none;
  svg {
    fill: $blanco;
    transform: rotate(180deg) translateY(2px);
		margin-right: $espaciado-16;
  }
  &:hover {
    color: $blanco;
  }
}
.pie {
  background-color: $AzulPrincipal;
  color: $blanco;
  font-size: $font-size-xs;
  padding: $espaciado-16 0;

  details {
    margin-bottom: 1rem;
  }
  details[open] > summary:first-of-type {
    svg {
      transform: rotate(180deg);
    }
  }
	details > summary::-webkit-details-marker {
		display: none;
	} 
  summary {
    width: 34%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ttpie {
    font-size: $font-size-xs;
    color: $verde;
    list-style: none;
    font-weight: 700;
    svg {
      fill: $verde;
    }
  }
  .cLogo {
    display: inline-block;
    margin-bottom: $espaciado-32;
    .logoV {
      width: 74px;
    }
    .logoH {
      display: none;
      width: 180px;
    }
  }
	.cRrss{
		margin-bottom: 2rem;
	}
  .rrss {
    margin-bottom: 0.5rem;
    svg {
      stroke: $blanco;
			fill: transparent;
			stroke-width: 1.5;
			width: 1.25rem;
			height: 1.25rem;
    }
  }
  .contacto {
    color: $verde;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    a {
      color: $blanco;
      margin: 0.5rem 0;
      display: block;
      text-decoration: none;
    }
  }
  .cc {
    margin-top: $espaciado-16;
    span {
      display: block;
    }
  }
  .logoCc {
    width: auto;
    height: 72px;
  }
}

@media (min-width: 576px) {
  .pie {
    .ttpie {
      font-size: $font-size-base;
      pointer-events: none;
      svg {
        display: none;
      }
    }
    .cLogo {
      .logoV {
        display: none;
      }
      .logoH {
        display: block;
      }
    }
		.cSello{
			margin-top: $espaciado-48;
		}
    details {
      margin-bottom: 0;
    }
    .app {
      display: block;
      max-width: 240px;
      .ttpie {
        font-size: $font-size-l;
        margin-bottom: 1rem;
      }
    }
    ul {
      a {
        font-size: $font-size-s;
        margin: 1rem 0;
      }
    }
    .cc {
      text-align: center;
      span {
        display: inline;
      }
    }
    .rrss {
      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
@media (min-width: 768px) {
  .pie {
    padding: 2rem 0;
    .container {
      position: relative;
    }
    .cc {
      span {
        display: inline;
      }
    }
		//comentar .logoCc cuando la app vuelva al footer
		// .logoCc{
		// 	margin-top: -200px;
		// }
  }
}

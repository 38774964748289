@import "../../../styles/_variables.module.scss";

.imgCard{
    border-radius: 10px;
}
.card {
		box-shadow: 0px 4px 14px 0px rgba(112, 144, 176, 0.1);
    border-radius: 10px;
    border: none;
}
.botonera {
    padding: 0px 0px $espaciado-16;
		margin-top: auto;
}
.ttCard {
    color: $negro;
    font: normal 600 16px $default-font;
    line-height: 22px;
}
.descripcionCard {
    color: $gris;
    font: normal normal 12px $default-font;
    line-height: 22px;
    margin-bottom: 16px;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
}
.cardBody {
	padding: 16px 16px 0 16px;
}

@media (min-width: 992px) {
    .ttCard {
        font: normal 600 24px $default-font;
        line-height: 30px;
    }
    .descripcionCard {
        font: normal normal 16px $default-font;
    }
}

.clickZone{
	position: absolute;
	width: 100%; 
	height: 100%; 
}

.cTipoCliente {
	display: flex;
	flex-direction: row;
	.contenedorInmobiliarias {
		margin: 16px 0 0 17px;
		background-color: $purpura;
		height: 16px;
		width: 87px;
		border-radius: 4px;
		.ttTipoCliente {
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;
			text-align: center;
			margin: 0px;
			color: rgba(0,0,0,0.5);
		}
	}
	.contenedorCorredoras {
		margin: 16px 0 0 17px;
		background-color: $rosa;
		height: 16px;
		width: 87px;
		border-radius: 4px;
		.ttTipoCliente {
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;
			text-align: center;
			margin: 0px;
			color: rgba(0,0,0,0.5);
		}
	}
}
@import "../../../styles/_variables.module.scss";

.contenedorNoticias {
	padding-top: $espaciado-64;

	.ttNoticias {
		font: $ttSeccionMobile;
		color: $AzulPrincipal;
		text-align: center;
		margin-bottom: $espaciado-24;
	}

	.btnOutline {
		margin: $espaciado 0;
		display: initial;
		padding: $espaciado $espaciado-64;
	}

	.contenedorBtnBlogs {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-top: $espaciado-24;
	}
}

.imgCard {
	width: auto;
}

.cardBody {
	display: flex;
	flex-direction: column;
}

.contenedorDescripcion {
	padding-bottom: $espaciado;
	margin-top: auto;
}

@media (max-width: 991px) {

	.contenedorBtnBlogs {
		padding-top: $espaciado-16;
	}
}

@media (min-width: 992px) {
	.contenedorNoticias {
		padding-top: $espaciado-112;
		
		.ttNoticias {
			margin-bottom: $espaciado-16;
			font: $ttSeccionDesktop;
		}
	}

	.contenedorDescripcion {
		font: normal normal 16px $default-font;
		padding-bottom: $espaciado-24;

	}
}